import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';

import { Autoplay, Pagination, FreeMode } from 'swiper/modules';

const zoekacties = [
    { name: 'Inzet Turkije februari 2023', href: '#' },
    { name: 'Inzet omgeving Geel (B)', href: '#' },
    { name: 'Zoekactie Loonse en Drunense duinen', href: '#' },
    { name: 'Grootschalige zoekactie naar Silvia (24) in Amsterdam', href: '#' },
    { name: '23 en 24 Juli 2021 Urgente zoekactie Loon/Gasteren', href: '#' },
    { name: '17 en 18 Juli 2021 Zoekactie Zuidlaren', href: '#' },
    { name: 'Spoedinzet Assen 26-6-2021', href: '#' },
]

const nieuws = [
    { name: 'Training met VRH', href: '#', img: '/Images/Training-VRH.jpg', text:'Zaterdag 22 juni heeft Oger SARdogs samen met de Vlaamse Reddingshonden VRH een leerzame trainingsdag in België gehad.' },
    { name: 'Levering VITALstyle Hond & Kat als sponsoring voor Oger SARdogs', href: '#', img: '/Images/Vital.jpg', text:'Vandaag heeft een van onze leden weer een geweldige levering met VITALstyle hondenbrokken mogen ontvangen ter sponsoring.' },
    { name: 'Teambuilding weekend', href: '#', img: '/Images/Oefenweekend-3.jpg', text:'Afgelopen weekend, 15 en 16 juni, beleefde Team Oger SARdogs een teambuilding weekend op het landgoed Mariahoeve.' },

]

const sponsers = [
    { name: 'Vital style', href: 'https://www.vitalstyle.nl/', img: '/Images/VITALstyle.png'},
    { name: 'Bork sloopwerken', href: 'https://www.borkgroep.nl/', img: '/Images/Bork-Sloopwerken.png'},
    { name: 'Lek sloopwerken', href: 'https://lek-sloopwerken.nl/', img: '/Images/LEK-Sloopwerken.png'},
]

function Home() {
  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 px-6 py-2.5">   

        <div className="border-b border-gray-200 pb-3 pt-3">
            <img alt='' src={process.env.PUBLIC_URL + '/Images/QRCode.png'} className='w-full'></img>
            <br></br>
            <button type='button' className='focus:outline-none text-white bg-lime-700 hover:bg-lime-800 focus:ring-4 focus:ring-lime-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-lime-600 dark:hover:bg-lime-700 dark:focus:ring-lime-800 w-full'>Doneer</button>
        </div>


        <div className="border-b border-gray-200 pb-3 pt-3">
            <h3 className="text-xl font-semibold leading-6 text-gray-900">Oger SARdogs</h3>
            <p className="mt-2 text-sm text-gray-500">
                Stichting Oger SARdogs biedt families, vrienden en kennissen de mogelijkheid zich te herenigen met hun dierbaren in de meest kwetsbare omstandigheden.
                Om dit te bewerkstelligen leidt Oger SARdogs honden en hun geleiders op voor het zoeken naar vermiste personen, zowel levend als overleden, in alle mogelijke omstandigheden.
                <br></br><br></br>
                De naam van de stichting is ontleend aan de Mechelse herder Oger die tot op hoge leeftijd vol passie aan vele zoekacties heeft meegewerkt.
                Oger bezat doorzettingsvermogen, flexibiliteit, sociale vaardigheden, werkdrift, werkethiek, buitdrift, teamspirit, goede fysieke conditie, focus, uithoudingsvermogen. Deze eigenschappen kan elke hond gebruiken en ook de hondengeleider doet er goed aan zich deze eigenschappen eigen te maken als ze die niet al bezitten.
                <br></br><br></br>
                Om resultaat bij zoekacties te bespoedigen werken we altijd nauw samen met verschillende instanties om het herenigen van dierbaren te bewerkstelligen.
                De stichting laat zich niet belemmeren door politieke standpunten en zet zich nationaal en internationaal onbaatzuchtig in voor het maatschappelijk belang.
                <br></br><br></br>
                Daarnaast stelt de Stichting in zijn trainingen het doel deze open en transparant te houden en een mogelijkheid te bieden aan andere hondengeleiders om kennis en ervaring op te doen en deze met elkaar te delen.
                Stichting Oger SARdogs wordt gedragen door vrijwilligers en financieel onderhouden door sponsoring en donaties. Onze vrijwilligers zijn in hun vrije tijd dagelijks bezig met de opleiding en verzorging van de eigen honden.
                <br></br><br></br>
                Voor meer informatie over wat te doen bij een vermissing kun je kijken op <a target='_blank' rel='noopener noreferrer' className='text-lime-600 font-semibold' href='https://www.rijksoverheid.nl/documenten/brochures/2014/08/12/wat-te-doen-als-iemand-vermist-wordt'>"Wat kan ik doen bij een vermissing"</a>
            </p>
        </div>

        <div className='border-b border-gray-200 pb-3 pt-3'>
            <div className='mx-auto max-w-7xl'>
                <div className='xl:grid xl:grid-cols-2 xl:gap-8'>
                    <div className='space-y-3'>
                        <h3 className="text-xl font-semibold leading-6 text-gray-900">Zoekacties</h3>
                        <p className="mt-2 text-sm text-gray-500 pl-5 pb-3">
                        
                            <ul>
                                {zoekacties.map((item) => (<li className='list-disc'><a key={item.name} href={item.href} className="text-sm hover:font-semibold leading-6 text-gray-500 hover:text-lime-600">{item.name}</a></li>))}   
                            </ul>
                            <br></br>
                            Kijk <a className='text-lime-600' href='Zoekacties'>hier</a> voor meer zoekacties die we hebben uitgevoerd.
                        </p>
                    </div>
                    <div className='space-y-3'>
                        <h3 className="text-xl font-semibold leading-6 text-gray-900">Laatste Nieuws</h3>
                        <p className="mt-2 text-sm text-gray-500">
                        
                            <ul>
                                {nieuws.map((item) => (<li className='pb-2'>
                                    <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900 hover:text-lime-600">{item.name}<br></br><p className='text-gray-500 font-normal'>{item.text}</p></a>
                                    </li>))}   
                            </ul>
                            <br></br>
                            <a className='text-lime-600 font-medium' href='Nieuws'>Lees alle nieuwsberichten</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div className='border-b border-gray-200 pb-3 pt-3'>
            <h3 className="text-xl font-semibold leading-6 text-gray-900 pb-5">TEKST</h3>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={30}
                    freeMode={true}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination, FreeMode]}
                    className="sponsors"
                >
                    {sponsers.map((item) => (
                        <SwiperSlide>
                            <a href={item.href} target='_blank' rel='noopener noreferrer'><img src={item.img} alt={item.name} className='h-28 w-auto pb-6'></img></a>
                        </SwiperSlide>
                    ))}
                </Swiper>                                          
        </div>                            

    </div>
  )
}

export default Home