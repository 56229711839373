import React from "react";

import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Top from "./components/Top";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      < Top />
      < Navigation />
      < Home />
      < Footer />
    </div>
  );
}

export default App;