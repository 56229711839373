import React from 'react';

function Top() {
  return (
    <div className="flex bg-lime-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 sticky top-0"><p className="text-sm leading-6 text-white">Wij zijn 7 dagen per week bereikbaar via ons alarmnummer: <strong className='font-semibold'>+31 (0) 622 689 140</strong></p>
    <div className="flex flex-1 justify-end"></div>
    </div>
  )
}

export default Top