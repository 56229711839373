import React from 'react';

function Footer() {
  return (
    <footer className='bg-gray-100'>
        <h2 id="footer-heading" className='sr-only'>Footer</h2>
        <div className='mx-auto max-w-7xl px-6 pb-8 pt-8 sm:pt-12 lg:px-8 lg:pt-12'>
            <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
                <div className='space-y-3 pb-3'>
                    <h2>ANBI</h2>
                    <p className='text-sm leading-6 text-gray-600'>Stichting Oger SARdogs is door de belastingdienst erkend als Algemeen Nut Beogende Instelling, ANBI.</p>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.donateursbelangen.nl/anbi-organisatie?recordId=Wg4kZChiXN6mAwhFymwigc '><img alt='' src={process.env.PUBLIC_URL + '/Images/ANBI-Logo.png'} className='h-20 w-auto'></img></a>
                </div>
                <div className='space-y-3 pb-3'>
                    <h2>Samenwerkingen</h2>
                    <p className='text-sm leading-6 text-gray-600'>Oger SARdogs is aangesloten bij de <a href='https://www.samenwerkendereddingshonden.eu/' target='_blank' rel='noopener noreferrer' className='text-lime-600'>Samenwerkende Reddingshonden Organisaties</a></p>
                </div>
                <div className='space-y-3 pb-3'>
                    <h2>Direct Doneren</h2>
                    <p className='text-sm leading-6 text-gray-600'>IBAN:NL79RABO0335188729 t.n.v. Stichting Oger SARdogs te Assen</p>
                </div>
            </div>
            <div className='mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24'>
                <p className='text-xs leading-5 text-gray-500'>&copy; 2024 Oger SARdogs</p>
            </div>
        </div>
    </footer>
  )
}

export default Footer